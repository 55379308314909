import { render, staticRenderFns } from "./ListInpirasi.vue?vue&type=template&id=4167610a&scoped=true&"
import script from "./ListInpirasi.vue?vue&type=script&lang=js&"
export * from "./ListInpirasi.vue?vue&type=script&lang=js&"
import style0 from "./ListInpirasi.vue?vue&type=style&index=0&id=4167610a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4167610a",
  null
  
)

export default component.exports