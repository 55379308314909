<template>
    <BaseLayout>
    <section class="">
        <div class="ol-wrapper ol-wrapper--large">
            <div class="ol-news__wrapper add-fix">
                <div class="ol-news__list ol-news__list-home">
                    <div class="break-32"></div>
                    <div class="ol-news__list-wrapper" style="text-align: center;">
                        <h2 class="ol-section-title">
                            <span>Inovasi {{ this.labelHeader }}</span>
                            <i></i>
                        </h2> 
                        <div>
                        <div  v-for="item in data" :key="item.id" class="ol-home-news__list-item ol-home-news__list-item-home" >
                            <div class="ol-home-news__list-item-images">
                                <figure class="ol-home-news__list-item-images-figure ol-home-news__list-item-images-figure-home">
                                    <a :href="item.link" target="_blank">
                                        <img :srcset="item.image_url" :alt="item.name" :title="item.name">
                                    </a>
                                </figure>
                            </div>
                            <div class="ol-home-news__list-item-desc ol-home-news__list-item-desc-home">
                                <a :href="item.link" target="_blank">
                                    <h3 class="ol-home-news__list-item-desc-title-home" style="height: 30px;color:black;font-size:16px;padding-top:3px;">
                                        {{item.name}}
                                    </h3>
                                    <!-- <p>
                                        {{ item.description }}
                                    </p> -->
                                </a>
                            </div>

                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
   </BaseLayout>
</template>
<script>

import "@/assets/css/bootstrap.css";
require("@/assets/js/imagesloaded.pkgd.min.js");
require("@/assets/js/masonry.pkgd.min.js");
require("@/assets/js/bootstrap.js");
import globalUrl from '@/util/globallUrl'
import BaseLayout from '@/components/landing/BaseLayout.vue';
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name: 'ListInpirasi',
    metaInfo() {
        return{
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components:{
        BaseLayout,
        SkeletonLoader
    },
    created(){
        window.addEventListener('load', () => {
        // https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements
        this.scrollHeight=this.$el.clientHeight;
        // $(".grid").imagesLoaded(function () {
        //     $(".grid").masonry({
        //         itemSelector: ".grid-item"
        //     });
            
        // });
        // this.$nextTick(() => {if(this.page == 1) this.scrollToEnd()});
  })
    },
    data(){
        return{
            input: {
                search: ""
            },
            loadingKey:6,
            page:1,
            per_page:9,
            loading:true,
            morePage:false,
            application:1,
            inspirasiKosong:false,
            room:1,
            data:[],
            scrollHeight:0,
            labelHeader:"",
            listFilter:[
                { id:null, name:'Pilih Semua'}
            ]
        }
    },
    updated() {
        
    },
    methods:{
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        async getData(){
            let _room = $('#searchFilter').val();
            // console.log(_room);
            let data={
                application:this.application,
                q:this.input.search,
                category: this.$route.params.slug,
                page : this.page,
                per_page:this.per_page
            };
            await globalUrl.WS_INSPIRASI.GET_LIST(data).then(response => {
                let _data = response.data.data;
                // console.log(_data);
                this.morePage = _data.pagination.more;
                this.data.push(..._data.results);
                if(this.data.length <1){
                    this.inspirasiKosong=true;
                }
                if(this.page>1 || this.input.search != "" || _room != null){
                    // console.log('destroy');
                    // $('.grid').masonry('destroy');
                }
                
                
            }).catch(error => {
                // console.log(error.data)
                
            });
            
            
        },
        async getFilter(){
            await globalUrl.WS_INSPIRASI.FILTER_ROOM().then(response => {
                let _data = response.data.data;
                this.listFilter.push(..._data);
            }).catch(error => {
                // console.log(error.data)
            });
        },
    },
    async mounted(){
        // console.log(this.$route.query)
        if(this.$route.query.q){
            this.input.search=this.$route.query.q;
        }
        this.jqScrolling2top()
        var vm = this;
        this.loading= true;
        await this.getData();
        this.loading= false;
        
        var slug = this.$route.params.slug;
        if(slug == "bedroom"){
            this.labelHeader = "Kamar Tidur";
        }else if(slug == "kitchen"){
            this.labelHeader = "Dapur";
        }else if(slug == "apartmen"){
            this.labelHeader = "Apartemen";
        }else if(slug == "living-classic"){
            this.labelHeader = "Ruang Tamu Klasik";
        }else if(slug == "living"){
            this.labelHeader = "Ruang Tamu";
        }

        var isInFullScreen = (document.fullScreenElement && document.fullScreenElement !==     null) ||    // alternative standard method  
            (document.mozFullScreen || document.webkitIsFullScreen);

    var docElm = document.documentElement;
    if (!isInFullScreen) {

        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        }
        else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
            alert("Mozilla entering fullscreen!");
        }
        else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
            alert("Webkit entering fullscreen!");
        }
    }
    }
    

}
</script>
<style scoped lang="css">
@import '../../assets/css/blog.css';

.ol-news__list .ol-home-news__list-item-desc-home {
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.break-32{
    display:block;
}

.retail-mobile{
    display:none;
}

@media(max-width:767px){
    .break-32{
        display:none;
    }

    .retail-mobile{
        display:block;
    }
}
</style>